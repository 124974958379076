/** @jsx jsx */
import { css, jsx } from "@emotion/core";
// import css from "@emotion/react";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FieldError, ServerErrors } from "@site/_helpers";
import { fetchPost } from "@lib/net-api/fetch";
import env from "env";

const formStyle = css`
  margin: 1rem 0.5rem;
  .text-red-600 {
    color: red;
    font-size: 80%;
  }

  .checkBoxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 1rem;

    .form-group {
      margin-bottom: 0.5rem;
    }
  }
`;

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  phone: yup.string().required("Phone is required."),
  email: yup.string().required("Email is required.").email("Invalid email."),
});

export function ContactAgentForm({ agentId, onSubmitted }) {
  const defaultValues = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    comment: "",
  };
  const {
    formState: { isSubmitting, errors },
    register,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const [serverErrors, setServerErrors] = React.useState([]);

  async function onSubmit(model) {
    try {
      const value = { ...model, agentId };
      const res = await fetchPost(env.apiBase + "/api/Form/ContactAgent", value);
      console.log(res);
      reset(defaultValues);
      setServerErrors([]);
      onSubmitted(true);
    } catch (e) {
      reset(model);
      setServerErrors(e);
    }
  }
  // console.log(isValid, dirtyFields, isSubmitting, errors);
  return (
    <div css={formStyle}>
      <form method="POST" noValidate={true} onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="firstName">First Name*</label>
              <input id="firstName" type="text" className="form-control" {...register("firstName")} />
              <FieldError error={errors?.firstName} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="lastName">Last Name*</label>
              <input id="lastName" type="text" className="form-control" {...register("lastName")} />
              <FieldError error={errors?.lastName} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="phone">Phone*</label>
              <input id="phone" type="text" className="form-control" {...register("phone")} />
              <FieldError error={errors?.phone} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input id="email" type="email" className="form-control" {...register("email")} />
              <FieldError error={errors?.email} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="comment">Message</label>
              <textarea className="form-control" id="comment" rows={4} {...register("comment")}></textarea>
              <FieldError error={errors?.comment} />
            </div>
          </div>
        </div>
        <div
          className="form-group"
          css={css`
            text-align: center;
          `}
        >
          <button type="submit" disabled={isSubmitting} className="btn btn-primary btn-block">
            {isSubmitting && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
            <span className="">Send</span>
          </button>
        </div>

        <ServerErrors errors={serverErrors} />
      </form>
    </div>
  );
}
