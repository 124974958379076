import _ from "lodash";
import React from "react";
import { PropertyListItem } from "./propertyListItem";

export function PropertyListView({ properties, showOpenInspectionDate = false }) {
  const [suburbFilter, setSuburbFilter] = React.useState(null);

  const suburbs = _.uniqWith(
    properties.filter((x) => !!x.suburb).map((x) => ({ name: getSuburbName(x.suburb), value: x.suburb.toLowerCase() })),
    (a, b) => a.value.toLowerCase() === b.value.toLowerCase()
  ).sort();
  const filtered = suburbFilter ? properties.filter((x) => x.suburb.toLowerCase() === suburbFilter) : properties;
  // console.log(data, suburbs);
  return (
    <>
      {suburbs.length > 0 && (
        <div className="filters">
          <div className="form-group">
            <select className="form-control" onChange={(e) => setSuburbFilter(e.target.value)}>
              <option value="">Suburb</option>
              {suburbs.map((x) => (
                <option key={x.value} value={x.value}>
                  {x.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      <div className="propertyList">
        {filtered.map((p) => (
          <PropertyListItem key={p.propertyId} property={p} showOpenInspectionDate={showOpenInspectionDate} />
        ))}
        {filtered.length === 0 && <div>No listing</div>}
      </div>
    </>
  );
}

function getSuburbName(suburb) {
  if (suburb === suburb.toUpperCase() || suburb === suburb.toLowerCase()) {
    return _.startCase(_.toLower(suburb));
  }
  return suburb;
}
