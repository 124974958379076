import React from "react";
import { LeadGeneratorPopup } from "./leadGenerator";
import "./searchPanel.scss";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export function SearchPanel() {
  const [address, setAddress] = React.useState("");
  const [showPopup, setShowPopup] = React.useState(false);

  function openPopup(e) {
    e.preventDefault();
    if (address) setShowPopup(true);
    console.log("openPopup", address);
  }
  function onChange(e) {
    setAddress(e.target.value);
  }
  function onSuccess(e) {
    setShowPopup(false);
    const ret = MySwal.fire({
      title: "Thank you!",
      text: "Successfully submitted.",
      icon: "success",
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: false,
    });
    console.log(ret);
  }
  return (
    <div className="searchBannerPanel">
      <div className="searchBannerPanel__content">
        <h2>How much is my property worth?</h2>
        <p>Get a detailed estimate of your property’s sale value in today’s market</p>

        <form className="searchInput" onSubmit={(e) => openPopup(e)}>
          <div className="searchInput__input">
            <input type="text" placeholder="Type your full address" value={address} onChange={onChange}></input>
          </div>
          <div className="searchInput__button" onClick={(e) => openPopup(e)}>
            FIND OUT
          </div>
        </form>
      </div>
      <LeadGeneratorPopup showPopup={showPopup} address={address} onSuccess={onSuccess} onClosed={() => setShowPopup(false)} />
    </div>
  );
}
