import React from "react";
import { OpenInspections } from "@site/propertyListing/openInspection/propertyList";

export function OpenHomes({ item, cmsOption }) {
  if (cmsOption.isCmsEdit)
    return (
      <pre
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
        style={{ fontSize: "12px", margin: "5px", padding: "10px", backgroundColor: "#eee", border: "border: 1px solid #ccc;" }}
      >
        <b>Type: {item?.itemTypeCode}</b>
      </pre>
    );

  return <OpenInspections />;
}
