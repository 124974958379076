import { ErrorPopup, Loading, useGet } from "components";
import env from "env";
import React from "react";
import { AgentDetails } from "./agentDetails";

export function AgentDetailsLoading({ agentId }) {
  const [loaded, setLoaded] = React.useState(false);
  const get = useGet();
  React.useEffect(() => {
    const url = `/api/property/getAgent/${agentId}`;
    get.send(env.apiBase + url);
    setLoaded(false);
    // eslint-disable-next-line
  }, [agentId]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />;
  }

  const data = get.response;
  // console.log(data);
  if (get.done() && !loaded) {
    setLoaded(true);
  }

  //if (data.totalCnt === 0) return <div>No product found.</div>;
  return (
    <React.Fragment>
      <AgentDetails agent={data} />
    </React.Fragment>
  );
}
