import React from "react";
import "./propertyList.scss";
import { getUrl } from "../propertyHelper";
import { SiteLink } from "components";
import { Helmet } from "react-helmet-async";

export function PropertyListWideItem({ property }) {
  const realEstateStructuredData = [
    {
      "@context": "https://schema.org",
      "@type": "RealEstateAgent",
      "name": "Ball Realty",
    },
    {
      "@context": "https://schema.org",
      "@type": "Offer",
      "url": getUrl(property),
      "name": property.address,
      "image": property.listImageUrl,
      "description": property.description,
      "availability": "http://schema.org/InStock",
      "seller": {
        "@type": "Organization",
        "image": "https://ballrealty.com.au/assets/logos/logo.png",
        "name": "Ball Realty",
        "address": {
          "@type": "PostalAddress",
          "addressCountry": "Australia",
          "addressLocality": "Pacific Pines Town Centre",
          "addressRegion": "QLD",
          "postalCode": "4211",
          "streetAddress": "Shop 7, 1 Pitcairn Way",
        },
      },
    },
  ];

  return (
    <>
      <Helmet>
        <script type='application/ld+json'>
          {JSON.stringify(realEstateStructuredData)}
        </script>
      </Helmet>
      <div className='propertyListItem propertyListItem--wide'>
        <SiteLink className='item-link' to={getUrl(property)}>
          <div className='img'>
            <img src={property.listImageUrl} alt={property.address} />
            {property.propertyStatus === "current" && property.isNew && (
              <div className='new'>
                <img src='/assets/icons/slash-NEW.png' alt='New' />
              </div>
            )}
            {property.propertyStatus === "sold" && (
              <div className='sold'>
                <img src='/assets/icons/slash-sold.png' alt='Sold' />
              </div>
            )}
            {property.propertyStatus === "leased" && (
              <div className='leased'>
                <img src='/assets/icons/slash-leased.png' alt='Leased' />
              </div>
            )}
            {property.propertyStatus !== "sold" &&
              property.propertyStatus !== "leased" &&
              property.underOffer && (
                <div className='underOffer'>
                  <img
                    src='/assets/icons/slash-under-contract.png'
                    alt='Under Offer'
                  />
                </div>
              )}
          </div>
          <div className='details'>
            <div className='subImgs'>
              {property.listImageUrl1 && (
                <div>
                  <img src={property.listImageUrl1} alt={property.address} />
                </div>
              )}
              {property.listImageUrl2 && (
                <div>
                  <img src={property.listImageUrl2} alt={property.address} />
                </div>
              )}
            </div>
            <div className='detailsOnly'>
              {property.agents.length > 0 && (
                <SiteLink
                  className='agent'
                  to={property.agents[0].agentUrl || `/meet-the-team`}
                >
                  <div className='agent__box'>
                    {property.agents[0].profileImage && (
                      <div className='agent__photo'>
                        <img
                          src={property.agents[0].profileImage}
                          alt={property.agents[0].name}
                        />
                      </div>
                    )}
                    <div className='agent__details'>
                      <div className='agent__name'>
                        {property.agents[0].name}
                      </div>
                      <div className='agent__position'>
                        {property.agents[0].position}
                      </div>
                    </div>
                  </div>
                </SiteLink>
              )}
              <div className='title'>{property.priceText}</div>
              <div className='addr'>{property.address}</div>
              {/* <div className="price">
            {saleOrRent(property)} {property.priceText}
          </div> */}
              <div className='pty-icons'>
                <span className='bedroom'>
                  <b>{property.bedroom}</b>
                </span>
                <span className='bathroom'>
                  <b>{property.bathroom}</b>
                </span>
                {property.parking && (
                  <span className='parking'>
                    <b>{property.parking}</b>
                  </span>
                )}
              </div>
              <div className='headline'>{property.headline}</div>
              <div className='desc'>{property.description}</div>
            </div>
          </div>
        </SiteLink>
      </div>
    </>
  );
}
