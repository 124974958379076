import React from "react";
import { PropertyListWideItem } from "../list/propertyListWideItem";
import Slider from "react-slick";

export function PropertySliderView({ properties }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="propertyList--wide">
        <Slider {...settings}>
          {properties.map((p) => (
            <PropertyListWideItem key={p.propertyId} property={p} />
          ))}
        </Slider>
      </div>
    </>
  );
}
