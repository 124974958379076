import variables from "./cssInJs/variables";

const cssEmotion = {
  breakpointNames: variables.breakpointNames,
  breakpoints: variables.breakpoints,
};

export default {
  cssEmotion,
};
