import { ErrorPopup, Loading, usePost } from "components";
import env from "env";
import React from "react";
import { PropertyListView } from "../list/propertyListView";
import "./openHomes.scss";

export function OpenInspections() {
  const post = usePost();
  React.useEffect(() => {
    post.send(env.apiBase + "/api/property/openInspections");
    // eslint-disable-next-line
  }, []);

  if (post.loading()) {
    return <Loading />;
  } else if (post.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={post.errors} />;
  }
  const data = post.response;
  return (
    <>
      {data.days.map((day) => (
        <div className="openHomeDay" key={day}>
          <h3>
            <strong>{day.dateText}</strong>
          </h3>
          <PropertyListView properties={day.properties} showOpenInspectionDate={true} />
        </div>
      ))}
    </>
  );
}
