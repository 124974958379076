/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react';
import { Portal } from "site/portal/portal";
import { getPopupStyle } from './popupStyle';
import { IoMdCloseCircleOutline } from 'react-icons/io'

export function Popup({ children, showPopup, onClosed, ...attrs }) {
  const options = { ...{ popupSize: 'medium' }, ...attrs }
  const s = getPopupStyle()
  return <React.Fragment>
    <Portal>
      <div css={[s.overlay, showPopup && s.show]}>
        <div css={[s.popup, s[`size${options.popupSize}`]]}>
          <div css={s.close} title="Close" onClick={onClosed}> <IoMdCloseCircleOutline size={30} /></div>
          <div css={s.content}>{showPopup && children}</div>
        </div>
      </div>
    </Portal>
  </React.Fragment>
}
