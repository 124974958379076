import React from "react";
import cmsUtils from "@site/_cmsItems/cmsUtils";
import { AgentDetailsLoading } from "@site/agentDetails/agentDetailsLoading";

export function AgentDetailsElt({ item, cmsOption }) {
  //const cmsOption = props.cmsOption || {};
  //console.log(cmsOption)
  //console.log('item', item);

  const agentId = cmsUtils.payload(item, "AgentId");
  // console.log(agentId);
  if (cmsOption.isCmsEdit)
    return (
      <pre
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
        style={{ fontSize: "12px", margin: "5px", padding: "10px", backgroundColor: "#eee", border: "border: 1px solid #ccc;" }}
      >
        <b>
          Type: {item?.itemTypeCode}, Agent ID: {agentId}
        </b>
      </pre>
    );

  return <AgentDetailsLoading agentId={agentId} />;
}
