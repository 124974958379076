import { ErrorPopup, Loading, usePost } from "components";
import { getListingTypeEnum } from "const";
import env from "env";
import React from "react";
import { PropertyListView } from "./list/propertyListView";
import { PropertySliderView } from "./slide/propertySlider";

export function PropertyList({ listingTypes, propertyStatuses, limit, displayType }) {
  const post = usePost();
  // console.log(listingTypes, propertyStatuses);

  const searchOpt = {};
  if (listingTypes && listingTypes.length > 0) {
    searchOpt.listingTypes = listingTypes.map((x) => getListingTypeEnum(x));
  }

  if (propertyStatuses && propertyStatuses.length > 0) {
    searchOpt.propertyStatuses = propertyStatuses;
  }

  searchOpt.limit = limit;
  searchOpt.sortBy = "DateDesc";

  React.useEffect(() => {
    post.send(env.apiBase + "/api/property/search", searchOpt);
    // eslint-disable-next-line
  }, []);

  if (post.loading()) {
    return <Loading />;
  } else if (post.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={post.errors} />;
  }
  const data = post.response;
  return <>{displayType === "slider" ? <PropertySliderView properties={data.properties} /> : <PropertyListView properties={data.properties} />}</>;
}
