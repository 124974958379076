import { mq } from "cssInJs";
import { rgba } from "polished";

export function getPopupStyle() {
  return {
    overlay: mq({
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      transition: "all 0.5s",
      // pointerEvents: 'none',
      background: rgba("#000", 0.8),
      zIndex: -1,
      opacity: 0,
      visibility: "hidden",
    }),
    show: mq({
      opacity: 1,
      visibility: "visible",
      zIndex: 3000,
    }),
    popup: mq({
      width: "90%",
      height: "85vh",
      maxWidth: 1000,
      background: "#ffffffe0",
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    }),
    sizemedium: mq({
      width: "90%",
      height: "85vh",
      maxWidth: 1000,
    }),
    sizesmall: mq({
      width: "90%",
      height: "85vh",
      maxWidth: 800,
      maxHeight: 400,
    }),
    sizeauto: mq({
      width: "90%",
      height: "auto",
      maxWidth: 800,
      maxHeight: "85vh",
    }),
    content: mq({
      padding: 20,
      height: "100%",
      maxHeight: "85vh",
      overflowY: "auto",
    }),
    close: mq({
      position: "absolute",
      right: 15,
      top: 5,
      zIndex: 3001,
      cursor: "pointer",
    }),
  };
}
