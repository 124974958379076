import React from 'react';
import cmsUtils from '../../cmsUtils';
import utils from '../../../../utils';
import { YoutubeBannerMobilePopup } from '../../../../lib/youtube/youtubeBanner--mobilePopup';
import { SearchPanel } from "@site/searchPanel/searchPanel";

export function VideoPanel(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  //console.log('item', item);

  const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard';
  const videoCode = cmsUtils.payload(item, 'YoutubeCode');
  const useSearchPanel = cmsUtils.payload(item, 'UseSearchPanel') || 'no';

  const posterFrameUrl = cmsUtils.payload(item, 'PosterFrameUrl', null);
  const playerId = 'ytBanner-' + (item.itemId).substr(0, 8);

  const video = {
    heightSet: heightSet,
    videoLinkOrId: videoCode,
    playerId: playerId
  }

  //const enableYoutube = env.isProd;
  //const canPlayVideo = () => utils.css.screenWidth() >= 1200;

  const cssClass = utils.classNames('cms_item', 'youtubePanel', useSearchPanel === 'yes' ? 'useSearchPanel' : 'noUseSearchPanel', item.cssClass || item.anchorName || '');

  //console.log(window.innerWidth, canPlayVideo())

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName} style={{position: 'relative',overflow:"hidden"}}>
      {/* {canPlayVideo() ? <YoutubeBanner video={video} /> : <BannerPanel item={item}></BannerPanel>} */}
      <YoutubeBannerMobilePopup video={video} canPlayVideo={true} posterImageUrl={utils.site.resourcePath(posterFrameUrl)} />
      {
        useSearchPanel === 'yes' && 
        <SearchPanel />
      }
    </div>
  );
}
