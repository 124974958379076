import React from "react";
import { PropertyList } from "@site/propertyListing/propertyList";
import cmsUtils from "@site/_cmsItems/cmsUtils";

export function PropertyListing({ item, cmsOption }) {
  //const cmsOption = props.cmsOption || {};
  //console.log(cmsOption)
  //console.log('item', item);

  const ListingType_Sales = cmsUtils.payload(item, "ListingType_Sales");
  const ListingType_Rental = cmsUtils.payload(item, "ListingType_Rental");
  const PropertyStatus_Current = cmsUtils.payload(item, "PropertyStatus_Current");
  const PropertyStatus_Sold = cmsUtils.payload(item, "PropertyStatus_Sold");
  const limit = cmsUtils.payload(item, "Limit");
  const displayType = cmsUtils.payload(item, "Display");

  // console.log(ListingType_Sales, ListingType_Rental, PropertyStatus_Current, PropertyStatus_Sold);

  const listingTypes = [];
  const propertyStatuses = [];
  if (ListingType_Sales && ListingType_Sales === "true") listingTypes.push("sale");
  if (ListingType_Rental && ListingType_Rental === "true") listingTypes.push("rent");
  if (PropertyStatus_Current && PropertyStatus_Current === "true") propertyStatuses.push("current");
  if (PropertyStatus_Sold && PropertyStatus_Sold === "true") {
    propertyStatuses.push("sold");
    propertyStatuses.push("leased");
  }

  if (cmsOption.isCmsEdit)
    return (
      <pre
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
        style={{ fontSize: "12px", margin: "5px", padding: "10px", backgroundColor: "#eee", border: "border: 1px solid #ccc;" }}
      >
        <b>Type: {item?.itemTypeCode}</b>
      </pre>
    );

  return <PropertyList listingTypes={listingTypes} propertyStatuses={propertyStatuses} limit={limit || 1000} displayType={displayType || "tile"} />;
}
