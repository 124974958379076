import { formatNumber } from "@lib/utils-core/text";
import { listingType } from "const";
import env from "env";
import React from "react";
import utils from "utils";

export function saleOrRent(property) {
  if (property.listingType === listingType.Sale) return "For Sale:";
  else if (property.listingType === listingType.Rent) return "For Rent:";
  else return "";
}

export function getSummary(property) {
  return {
    bannerUrl: getBanner(property),
    body: property?.json?.advert_internet?.body || property?.json?.advert_brochure?.body,
    images: [...getFloorplans(property), ...getImages(property)],
    mapAddress: getMapAddress(property),
    features: getFeatures(property),
    agents: property.agents || [],
  };
}

function getBanner(property) {
  const image = property?.json?.images && property?.json?.images.length > 0 && property?.json?.images[0];
  if (image) {
    return image.url;
  }
  return "/sites/ballrealty/media/banners/adobestock_70730821.jpg";
}

function getImages(property) {
  const images = (property?.json?.images && property?.json?.images.length > 0 && property?.json?.images) || [];
  return images.map((img) => ({ imageUrl: img.url, thumbnailUrl: img?.thumbs?.["400x300"] }));
}

function getFloorplans(property) {
  const floorplans = (property?.json?.floorplans && property?.json?.floorplans.length > 0 && property?.json?.floorplans) || [];
  return floorplans.map((img) => ({ imageUrl: img.url, thumbnailUrl: img?.thumbs?.["400x300"] }));
}

function getMapAddress(property) {
  const state = property?.json?.address?.state_or_region;
  const address = encodeURIComponent(`${property.address} ${state}`.replace(/&/gi, "/"));
  return `https://www.google.com/maps/embed/v1/place?key=${env.gmapApiKey}&q=${address}`;
}

function getFeatures(property) {
  const features = [];
  const attrs = property?.json?.attributes || {};
  if (attrs?.landarea_m2)
    features.push(
      <span>
        Land size: <b>{formatNumber(attrs?.landarea_m2)}m²</b>
      </span>
    );
  if (attrs?.buildarea_m2)
    features.push(
      <span>
        Building size: <b>{formatNumber(attrs?.buildarea_m2)}m²</b>
      </span>
    );
  return [...features, ...(property?.json?.features || [])];
}

// function getAgents(property) {
//   const agents = [];
//   if (property?.json?.listing_agent_1) agents.push(getAgent(property?.json?.listing_agent_1, property?.json?.location));
//   if (property?.json?.listing_agent_2) agents.push(getAgent(property?.json?.listing_agent_2, property?.json?.location));
//   return agents;
// }

// function getAgent(agent, location) {
//   return {
//     email: agent.email_address,
//     name: agent.name,
//     id: agent.id,
//     mobile: agent.phone_mobile || agent.phone_direct,
//     position: agent.position,
//     location: location?.address_physical,
//     profileImage: agent.profile_image?.thumbs?.["700x875"]?.url || agent.profile_image?.url,
//   };
// }

export function getUrl(property) {
  return `/property/${property.propertyId}-${utils.url.getSlug(property.suburb)}`;
}

export function getPropertyIdFromUrl(path) {
  if (!path) return null;
  const parts = path.split("-");
  if (parts && parts.length > 0) return parts[0];
  return null;
}
