/** @jsx jsx */
import { css, jsx } from "@emotion/core";
// import css from "@emotion/react";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FieldError, ServerErrors } from "@site/_helpers";
import { fetchPost } from "@lib/net-api/fetch";
import env from "env";
import { gtm } from '../../../lib/tracking';

const formStyle = css`
  margin: 0.5rem 0;
  padding: 0.5rem;
  background: #eee;
  .text-red-600 {
    color: red;
    font-size: 80%;
  }

  .checkBoxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 1rem;

    .form-group {
      margin-bottom: 0.5rem;
    }
  }
`;

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  phone: yup.string().required("Phone is required."),
  email: yup.string().required("Email is required.").email("Invalid email."),
  reason: yup.string().required("Required."),
});

export function ContactAgentForm({ property, contactType, onSubmitted }) {
  const defaultValues = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    comment: "",
    howHelps: contactType === "inspection" ? ["Scheduling an inspection"] : [],
    reason: "",
    inspection: "",
  };

  const {
    formState: { isSubmitting, errors },
    register,
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const [serverErrors, setServerErrors] = React.useState([]);

  React.useEffect(() => {
    const howHelps = getValues("howHelps");
    if (contactType === "inspection" && howHelps.indexOf("Scheduling an inspection") < 0) howHelps.push("Scheduling an inspection");
    reset({ howHelps: howHelps });
  }, [property, contactType, reset, getValues]);

  async function onSubmit(model) {
    try {
      const value = { ...model, propertyId: property.propertyId };
      const res = await fetchPost(env.apiBase + "/api/Form/ContactAgentForProperty", value);
      console.log(res);
      reset(defaultValues);
      setServerErrors([]);
      onSubmitted(true);
      
      if (property.listingType === 0) {
        gtm.sendEvent('propertyEnquiryFormBuyerSubmitted',
          { contactEmail: value.email, propertyId: value.propertyId }
        );
      }
      else if (property.listingType === 1) {
        gtm.sendEvent('propertyEnquiryFormRentalSubmitted',
          { contactEmail: value.email, propertyId: value.propertyId }
        );
      }

    } catch (e) {
      reset(model);
      setServerErrors(e);
    }
  }

  const howHelps = ["Scheduling an inspection", "Price information", "Rates & Fees", "Similar properties"];
  const reasons = ["Buyer", "First Home Buyer", "Investor", "Upsizer", "Downsizer", "Developer", "Monitoring the market", "Seller", "Other"];

  // console.log(isValid, dirtyFields, isSubmitting, errors);
  return (
    <div css={formStyle}>
      <form method="POST" noValidate={true} onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-4">
          <div className="col-12">
            <label>
              <b>How can we help you?</b>
            </label>
          </div>
          <div className="col-12 checkBoxes">
            {howHelps.map((howHelp) => (
              <div className="form-group" key={howHelp}>
                <div className="form-check">
                  <label className="form-check-label">
                    <input type="checkbox" className="form-check-input" value={howHelp} {...register("howHelps")} />
                    {howHelp}
                  </label>
                  <FieldError error={errors?.howHelps} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="aboutMe">Which of the following applies to you?*</label>
              <select className="form-control" id="aboutMe" {...register("reason")}>
                <option value="">Select...</option>
                {reasons.map((reason) => (
                  <option key={reason}>{reason}</option>
                ))}
              </select>
              <FieldError error={errors?.reason} />
            </div>
          </div>
        </div>
        {property?.openInspections && property.openInspections.length > 0 && (
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="inspection">Inspection Date</label>
                <select className="form-control" id="inspection" {...register("inspection")}>
                  <option value="">Select...</option>
                  {property.openInspections.map((x) => (
                    <option key={x.inspectionDateTime} value={x.inspectionDateTime}>
                      {x.inspectionText}
                    </option>
                  ))}
                </select>
                <FieldError error={errors?.inspection} />
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="comment">Leave a comment</label>
              <textarea className="form-control" id="comment" rows={4} {...register("comment")}></textarea>
              <FieldError error={errors?.comment} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="firstName">First Name*</label>
              <input id="firstName" type="text" className="form-control" {...register("firstName")} />
              <FieldError error={errors?.firstName} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="lastName">Last Name*</label>
              <input id="lastName" type="text" className="form-control" {...register("lastName")} />
              <FieldError error={errors?.lastName} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="phone">Phone*</label>
              <input id="phone" type="text" className="form-control" {...register("phone")} />
              <FieldError error={errors?.phone} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input id="email" type="email" className="form-control" {...register("email")} />
              <FieldError error={errors?.email} />
            </div>
          </div>
        </div>

        <div
          className="form-group"
          css={css`
            text-align: center;
          `}
        >
          <button type="submit" disabled={isSubmitting} className="btn btn-primary btn-block">
            {isSubmitting && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
            <span className="">Send</span>
          </button>
        </div>

        <ServerErrors errors={serverErrors} />
      </form>
    </div>
  );
}
