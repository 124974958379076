import React from "react";
import "./propertyList.scss";
import { saleOrRent, getUrl } from "../propertyHelper";
import { SiteLink } from "components";
import { Helmet } from "react-helmet-async";

export function PropertyListItem({ property, showOpenInspectionDate }) {
  // console.log(property);
  const realEstateStructuredData = [
    {
      "@context": "https://schema.org",
      "@type": "RealEstateAgent",
      "name": "Ball Realty",
    },
    {
      "@context": "https://schema.org",
      "@type": "Offer",
      "url": getUrl(property),
      "name": property.address,
      "image": property.listImageUrl,
      "description": property.description,
      "availability": "http://schema.org/InStock",
      "seller": {
        "@type": "Organization",
        "image": "https://ballrealty.com.au/assets/logos/logo.png",
        "name": "Ball Realty",
        "address": {
          "@type": "PostalAddress",
          "addressCountry": "Australia",
          "addressLocality": "Pacific Pines Town Centre",
          "addressRegion": "QLD",
          "postalCode": "4211",
          "streetAddress": "Shop 7, 1 Pitcairn Way",
        },
      },
    },
  ];

  return (
    <>
      <Helmet>
        <script type='application/ld+json'>
          {JSON.stringify(realEstateStructuredData)}
        </script>
      </Helmet>
      <div className='propertyListItem'>
        <SiteLink className='item-link' to={getUrl(property)}>
          <div className='img'>
            <img src={property.listImageUrl} alt={property.address} />
            {property.propertyStatus === "current" && property.isNew && (
              <div className='new'>
                <img src='/assets/icons/slash-NEW.png' alt='New' />
              </div>
            )}
            {property.propertyStatus === "sold" && (
              <div className='sold'>
                <img src='/assets/icons/slash-sold.png' alt='Sold' />
              </div>
            )}
            {property.propertyStatus === "leased" && (
              <div className='leased'>
                <img src='/assets/icons/slash-leased.png' alt='Leased' />
              </div>
            )}
            {property.propertyStatus !== "sold" &&
              property.propertyStatus !== "leased" &&
              property.underOffer && (
                <div className='underOffer'>
                  <img
                    src='/assets/icons/slash-under-contract.png'
                    alt='Under Offer'
                  />
                </div>
              )}
            {showOpenInspectionDate && property.openInspections.length > 0 && (
              <div className='openHome'>
                Open Home: {property.openInspections[0].timeText}
              </div>
            )}
          </div>
          <div className='details'>
            <div className='title'>{property.suburb}</div>
            <div className='addr'>{property.street}</div>
            <div className='price'>
              {saleOrRent(property)} {property.priceText}
            </div>
            <div className='pty-icons'>
              <span className='bedroom'>
                <b>{property.bedroom}</b>
              </span>
              <span className='bathroom'>
                <b>{property.bathroom}</b>
              </span>
              {property.parking && (
                <span className='parking'>
                  <b>{property.parking}</b>
                </span>
              )}
            </div>
          </div>
        </SiteLink>
      </div>
    </>
  );
}
