import React from "react";
import utils from "utils";

import { Portal } from "site/portal/portal";
import FsLightbox from "fslightbox-react";
import { SiteLink } from "components";
import "./gallery.scss";

export function Gallery({ images }) {
  const [toggler, setToggler] = React.useState(false);
  const [popupIndex, setPopupIndex] = React.useState(0);

  if (images.length === 0) return null;

  const popupImages = images.map((x) => x.imageUrl);

  function openPopupImage(e, image) {
    e.stopPropagation();
    e.preventDefault();
    const imageUrl = image.imageUrl;
    const inx = utils.array.findIndex(popupImages, (x) => x === imageUrl);
    console.log(inx);
    setPopupIndex(inx >= 0 ? inx : 0);
    setToggler(!toggler);
  }

  return (
    <React.Fragment>
      <Portal>
        <FsLightbox toggler={toggler} sources={popupImages} sourceIndex={popupIndex} type="image" />
      </Portal>
      <div className="propertyGallery">
        {images.map((image, inx) => (
          <ImageTile image={image} key={inx} openPopupImage={openPopupImage} />
        ))}
      </div>
    </React.Fragment>
  );
}

function ImageTile({ image, openPopupImage }) {
  const thumbnailUrl = image.thumbnailUrl || image.imageUrl;
  const tileLink = image.imageUrl;

  return (
    <SiteLink className="propertyGalleryItem" to={tileLink} onClick={(e) => openPopupImage(e, image)}>
      <img src={thumbnailUrl} alt={tileLink} />
    </SiteLink>
  );
}
