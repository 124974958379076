import { gtm } from "@lib/tracking";
import { ViewPartial } from "cms/pages/partials/viewPartial";
import { ErrorPopup, Loading, MetaTag, NotFound, useGet, useRouter } from "components";
import env from "env";
import React from "react";
import { useStore } from "store";
import utils from "utils";
import isEqual from "lodash/isEqual";
import { PropertyView } from "./propertyView";
import { getPropertyIdFromUrl } from "./propertyHelper";

export function PropertyViewPage(props) {
  const { query } = useRouter();
  const { state, dispatch } = useStore();
  const [loaded, setLoaded] = React.useState(false);
  const [menu, setMenu] = React.useState(null);
  const get = useGet();

  const propertyId = getPropertyIdFromUrl(query.id || "");

  React.useEffect(() => {
    const url = `/api/property/getProperty/${propertyId}`;
    get.send(env.apiBase + url);
    setLoaded(false);
    // eslint-disable-next-line
  }, [propertyId]);

  React.useEffect(() => {
    if (menu && !isEqual(state?.menu, menu)) {
      dispatch({ type: "SET_MENU", payload: menu });
    }
    // eslint-disable-next-line
  }, [menu]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors() && get.containsErrorMessage("no property")) {
    return <NotFound />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />;
  }

  //   console.log(get);
  const data = get.response;

  if (get.done() && !loaded) {
    setLoaded(true);
    setMenu(data.menu);
    const pageUrl = `/property/${propertyId}`;
    const pageTitle = ""; // data.totalCnt === 0 ? "No product found" : data.productName;
    gtm.pageView(pageUrl, pageTitle);
  }

  //if (data.totalCnt === 0) return <div>No product found.</div>;
  return (
    <React.Fragment>
      <MetaTag data={getMetaTag(data.data)} />
      <PropertyView property={data.data} />
      {/* <div
        className="single-banner banner-item bannerSize--thin"
        style={{
          backgroundImage: utils.css.bgUrlStyle(utils.site.resourcePath(topBanner)),
        }}
      ></div> */}
      {/* <Product product={data} />

      <CommonProductBottom /> */}
      <ViewPartial />
    </React.Fragment>
  );
}

function getMetaTag(data) {
  //   console.log(data);
  //const country = utils.site.isNZ? 'New Zealand' : 'Australia'
  const country = "Australia";
  const siteName = env.siteName;
  const titleSuffix = env.titleSuffix;

  return {
    title: data.address + " " + data.suburb + " | " + titleSuffix,
    description: data.address + " " + data.suburb,
    heroImageUrl: data.listImageUrl,
    language: "English",
    country: country,
    siteName: siteName,
    url: utils.site.fullUrl(`/property/${data.propertyId}`),
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  };
}
