import React from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';

export function BottomDock(props) {
  const links = [
    { url: '/about', title: 'ABOUT', icon: 'about.png', iconHover: 'about-WHITE.png' },
    { url: '/buy', title: 'BUY', icon: 'buy.png', iconHover: 'buy-WHITE.png' },
    { url: '/sell', title: 'SELL', icon: 'sell.png', iconHover: 'sell-WHITE.png' },
    { url: '/testimonials', title: 'TESTIMONIALS', icon: 'orange-testimonials-icon.png', iconHover: 'white-testimonials-icon.png' },
    { url: '/blog', title: 'BLOG', icon: 'blog.png', iconHover: 'blog-WHITE.png' }
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
        <div className="icon">
          <img className="normal" src={`/assets/navicons/${link.icon}`} alt={link.title} />
          <img className="selected" src={`/assets/navicons/${link.iconHover}`} alt={link.title} />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  );

  return (
    <>
      <nav className="bottomMenu">
        {/* <div className="bottomMenu__bg"></div> */}
        <ul className="bottomMenu__links">
          {linkElts}
        </ul>
      </nav>
    </>
  );
}
