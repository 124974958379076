import React from "react";
import utils from "utils";
import { ShareButtons } from "site/shareButtons/shareButtons";
import { getSummary, getUrl, saleOrRent } from "./propertyHelper";
import { Gallery } from "./gallery/gallery";
import { FiMail, FiPhoneCall } from "react-icons/fi";
import "./propertyView.scss";
import env from "env";
import { ContactAgentForm } from "./forms/contactAgent";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { SiteLink } from "components";
import { FaAngleDown } from "react-icons/fa";
import { Helmet } from "react-helmet-async";

const MySwal = withReactContent(Swal);

export function PropertyView({ property }) {
  const [showShareButtons, setShowShareButtons] = React.useState(false);
  const [showMore, setShowMore] = React.useState(false);
  const [contactType, setContactType] = React.useState("hide");
  const shareUrl = env.baseOrigin + getUrl(property);
  //const shareUrl = utils.site.fullUrl(window.location.pathname + window.location.search)
  const pageTitle = property.address;
  const summary = getSummary(property);
  const addressRegion = summary.mapAddress.split("%20");

  const singleFamilyResidenceStructuredData = [
    {
      "@context": "https://schema.org",
      "@type": "RealEstateAgent",
      "name": "Ball Realty",
    },
    {
      "@context": "https://schema.org",
      "@type": "Offer",
      "url": getUrl(property),
      "name": property.address,
      "image": property.listImageUrl,
      "description": property.description,
      "availability": "http://schema.org/InStock",
      "seller": {
        "@type": "Organization",
        "image": "https://ballrealty.com.au/assets/logos/logo.png",
        "name": "Ball Realty",
        "address": {
          "@type": "PostalAddress",
          "addressCountry": "Australia",
          "addressLocality": "Pacific Pines Town Centre",
          "addressRegion": "QLD",
          "postalCode": "4211",
          "streetAddress": "Shop 7, 1 Pitcairn Way",
        },
      },
    },
    {
      "@context": "https://schema.org",
      "@type": "SingleFamilyResidence",
      "name": property.street + ", " + property.suburb,
      "description": summary.body,
      "photo": summary.images.map((x) => x.imageUrl),
      "numberOfBedrooms": property.bedroom,
      "numberOfBathroomsTotal": property.bathroom,
      "address": {
        "@type": "PostalAddress",
        "addressCountry": "Australia",
        "addressLocality": property.suburb,
        "addressRegion": addressRegion[addressRegion.length - 1],
        "streetAddress": property.street,
      },
    },
  ];

  function toggleShowShareButtons() {
    setShowShareButtons(!showShareButtons);
  }

  function goToAgent() {
    setContactType("contact");
    utils.ui.scrollTo(".agent");
    // document.querySelector(".agent").scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
  }

  function goToInspection() {
    setContactType("inspection");
    utils.ui.scrollTo(".agent");
  }

  function onContactSubmitted() {
    const ret = MySwal.fire({
      title: "Thank you!",
      text: "Successfully submitted.",
      icon: "success",
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: false,
      showConfirmButton: false,
      timer: 2000,
    });
    console.log(ret);
  }
  return (
    <React.Fragment>
      <Helmet>
        <script type='application/ld+json'>
          {JSON.stringify(singleFamilyResidenceStructuredData)}
        </script>
      </Helmet>
      <div
        className='single-banner banner-item bannerSize--standard'
        style={{
          backgroundImage: utils.css.bgUrlStyle(summary.bannerUrl),
        }}
      ></div>
      <div className='propertyViewHdr'>
        <div className='container'>
          <h1 className='address'>
            {property.street}, {property.suburb}
            {property.underOffer && (
              <div className='underOffer'>Under Contract</div>
            )}
          </h1>
        </div>
      </div>
      <div className='container propertyView'>
        <h2 className='headline'>{property.headline}</h2>
        <div className='price-icons'>
          <div className='price'>
            {" "}
            {saleOrRent(property)} {property.priceText}
          </div>
          <div className='pty-icons'>
            <span className='bedroom'>
              <b>{property.bedroom}</b>
            </span>
            <span className='bathroom'>
              <b>{property.bathroom}</b>
            </span>
            {property.parking && (
              <span className='parking'>
                <b>{property.parking}</b>
              </span>
            )}
          </div>
        </div>
        <div style={{ marginTop: "0.5rem" }}>
          <button
            className='btn btn-outline-primary btn-sm'
            type='button'
            style={{ marginRight: "0.5rem" }}
            onClick={goToAgent}
          >
            Get in touch
          </button>
          <button
            className='btn btn-outline-primary btn-sm'
            type='button'
            style={{ marginRight: "0.5rem" }}
            onClick={goToInspection}
          >
            Book an inspection
          </button>
          <button
            className='btn btn-outline-primary btn-sm'
            type='button'
            style={{ marginRight: "0.5rem" }}
            onClick={toggleShowShareButtons}
          >
            Share
          </button>
        </div>
        {showShareButtons && (
          <ShareButtons
            shareUrl={shareUrl}
            pageTitle={pageTitle}
            topLabel={null}
          />
        )}

        <div className='row'>
          <div className='col-md-6 details'>
            <div className='desc'>
              <div className={`descText ${showMore && "showAll"}`}>
                {summary.body}
              </div>
              <button
                className='btn btn-link btn-sm showMoreBtn'
                type='button'
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Read less" : "Read more"}
              </button>
            </div>
            <hr />
            <section>
              <div className='sectionTitle'>Property features</div>
              <ul className='features'>
                {summary.features.map((feature, inx) => (
                  <li key={inx}>{feature}</li>
                ))}
              </ul>
            </section>
            {property.openInspections.length > 0 && (
              <>
                <hr />
                <section>
                  <div className='sectionTitle'>Open Inspections</div>
                  <div className='inspections'>
                    {property.openInspections.map((inspection, inx) => (
                      <div className='inspection' key={inx}>
                        {inspection.inspectionText}
                      </div>
                    ))}
                  </div>
                </section>
              </>
            )}
            <hr />
            <section>
              {summary.agents.map((agent) => (
                <Agent agent={agent} key={agent.agentId} />
              ))}
            </section>
            <hr />
            <section>
              <div className='sectionTitle'>
                {contactType === "hide" && (
                  <button
                    className='btn btn-sm btn-light'
                    style={{ float: "right" }}
                    onClick={() => setContactType("contact")}
                  >
                    show
                    <FaAngleDown />
                  </button>
                )}
                Contact agents
              </div>
              {contactType !== "hide" && (
                <ContactAgentForm
                  property={property}
                  contactType={contactType}
                  onSubmitted={onContactSubmitted}
                />
              )}
            </section>
          </div>
          <div className='col-md-6'>
            <Gallery images={summary.images} />
          </div>
        </div>
        <div className='row'>
          <div className='col-12' style={{ paddingTop: "2rem" }}>
            <iframe
              width='100%'
              height='450'
              frameBorder='0'
              style={{ border: 0 }}
              title={property.address}
              src={summary.mapAddress}
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function Agent({ agent }) {
  return (
    <div className='agent'>
      <div className='agentPhotoCol'>
        <div className='agentPhoto'>
          <img src={agent.profileImage} alt={agent.name} />
        </div>
      </div>
      <div className='agentDetailsCol'>
        <div className='agentName'>
          {agent.agentUrl ? (
            <SiteLink to={agent.agentUrl}>{agent.name}</SiteLink>
          ) : (
            <span>{agent.name}</span>
          )}
        </div>
        <div>
          <a href={`tel:${agent.phone}`}>
            <FiPhoneCall /> {agent.phone}
          </a>
        </div>
        <div>
          <a href={`mailto:${agent.email}`}>
            <FiMail /> {agent.email}
          </a>
        </div>
        <div>
          <span style={{ fontSize: "90%" }}>{agent.location}</span>
        </div>
      </div>
    </div>
  );
}
