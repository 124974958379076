/** @jsx jsx */
import { css, jsx } from "@emotion/core";
// import css from "@emotion/react";
import React from "react";
import { Popup } from "site/_popup/popup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FieldError, ServerErrors } from "@site/_helpers";
import { fetchPost } from "@lib/net-api/fetch";
import env from "env";
import { gtm } from '../../lib/tracking';

export function LeadGeneratorPopup({ showPopup, onClosed, onSuccess, address }) {
  return (
    <Popup showPopup={showPopup} onClosed={onClosed} popupSize="auto">
      <div>
        <h2
          css={css`
            font-size: 2rem;
            text-align: center;
          `}
        >
          Confirm your details
        </h2>
        <LeadGeneratorForm address={address} onSubmitted={() => onSuccess()} />
      </div>
    </Popup>
  );
}

const formStyle = css`
  margin: 1rem 0.5rem;
  .text-red-600 {
    color: red;
    font-size: 80%;
  }
`;

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  phone: yup.string().required("Phone is required."),
  email: yup.string().required("Email is required.").email("Invalid email."),
  address: yup.string().required("Address is required."),
  aboutMe: yup.string().required("About Me is required."),
});

function LeadGeneratorForm({ address, onSubmitted }) {
  const defaultValues = {
    address: address,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    aboutMe: "",
  };
  const {
    formState: { isSubmitting, errors },
    register,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const [serverErrors, setServerErrors] = React.useState([]);

  async function onSubmit(model) {
    try {
      const res = await fetchPost(env.apiBase + "/api/Form/LeadForm", model);
      console.log(res);
      reset(defaultValues);
      setServerErrors([]);
      onSubmitted(true);

      gtm.sendEvent('homepageLeadGeneratorFormSubmitted', 
        {contactEmail: model.email}
      );
      
    } catch (e) {
      reset(model);
      setServerErrors(e);
    }
  }

  // console.log(isValid, dirtyFields, isSubmitting, errors);
  return (
    <div css={formStyle}>
      <form method="POST" noValidate={true} onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="firstName">First Name*</label>
              <input id="firstName" type="text" className="form-control" {...register("firstName")} />
              <FieldError error={errors?.firstName} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="lastName">Last Name*</label>
              <input id="lastName" type="text" className="form-control" {...register("lastName")} />
              <FieldError error={errors?.lastName} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="phone">Phone*</label>
              <input id="phone" type="text" className="form-control" {...register("phone")} />
              <FieldError error={errors?.phone} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="email">Email*</label>
              <input id="email" type="email" className="form-control" {...register("email")} />
              <FieldError error={errors?.email} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="address">Address*</label>
              <input id="address" type="text" className="form-control" {...register("address")} />
              <FieldError error={errors?.address} />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="aboutMe">About Me*</label>
          <select className="form-control" id="aboutMe" {...register("aboutMe")}>
            <option value="">Select...</option>
            <option>I am thinking of selling</option>
            <option>I am just researching the market</option>
            <option>I am thinking of having my property managed and would like to know the potential rent per week</option>
          </select>
          <FieldError error={errors?.aboutMe} />
        </div>
        <div
          className="form-group"
          css={css`
            text-align: center;
          `}
        >
          <button type="submit" disabled={isSubmitting} className="btn btn-primary">
            {isSubmitting && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
            <span className="">Submit</span>
          </button>
        </div>

        <ServerErrors errors={serverErrors} />
      </form>
    </div>
  );
}
