import React, { useEffect } from "react";
import "./agentDetails.scss";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { PropertyListView } from "@site/propertyListing/list/propertyListView";
import { ContactAgentForm } from "./forms/contactAgent";
import { useRouter } from "components";
import utils from "utils";
const MySwal = withReactContent(Swal);

export function AgentDetails({ agent }) {
  const { location } = useRouter();
  const [showAgentContact, setShowAgentContact] = React.useState(location.hash === "#contact-agent");

  useEffect(() => {
    if (location.hash === "#contact-agent") utils.ui.scrollTo("#contact-agent", 500);
    // eslint-disable-next-line
  }, []);

  function onContactSubmitted() {
    const ret = MySwal.fire({
      title: "Thank you!",
      text: "Successfully submitted.",
      icon: "success",
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: false,
      showConfirmButton: false,
      timer: 2000,
    });
    console.log(ret);
  }
  return (
    <React.Fragment>
      <div className="agentSection">
        <div className="htmlPanel">
          <h3 id="contact-agent">
            <strong>Contact</strong>
          </h3>
          <AgentContact agent={agent} />
          {!showAgentContact && (
            <div style={{ margin: "0.5rem 0" }}>
              <button type="button" className="btn btn-primary" onClick={() => setShowAgentContact(true)}>
                <span className="">Contact Agent</span>
              </button>
            </div>
          )}
          {showAgentContact && (
            <div>
              <ContactAgentForm agentId={agent.agentId} onSubmitted={onContactSubmitted} />
            </div>
          )}
        </div>
      </div>
      {agent.recentListings.length > 0 && (
        <div className="agentSection">
          <div className="htmlPanel">
            <h3>
              <strong>Recent Listings</strong>
            </h3>
            <PropertyListView properties={agent.recentListings} />
          </div>
        </div>
      )}
      {agent.recentlySoldListings.length > 0 && (
        <div className="agentSection">
          <div className="htmlPanel">
            <h3>
              <strong>Recently Sold/Leased</strong>
            </h3>
            <PropertyListView properties={agent.recentlySoldListings} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

function AgentContact({ agent }) {
  return (
    <div className="agentContact">
      <div className="row">
        {agent.mobile && <AgentLineItem name="Mobile" value={agent.mobile} type="tel" />}
        {agent.phone && agent.phone !== agent.mobile && <AgentLineItem name="Office" value={agent.phone} type="tel" />}
        <AgentLineItem name="Email" value={agent.email} type="mailto" />
      </div>
    </div>
  );
}

function AgentLineItem({ name, value, type = null }) {
  const link = type ? <a href={`${type}:${value}`}>{value}</a> : <span>{value}</span>;
  return (
    <div className="col-md-6">
      <b>{name}:</b>
      {link}
    </div>
  );
}
