import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./polyfill";

import React from "react";
import { createRoot } from "react-dom/client";
import "./_scss/index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// ReactDOM.render(<App />, document.getElementById('root'));
const root = createRoot(document.getElementById("root"));
root.render(<App />);
//ReactDOM.hydrate(<App />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration) => {
    console.log("updated", registration);
    const update = true; //window.confirm('New Update Available. Do you want to update? (Refresh page)');
    if (update) {
      if (!registration.waiting) {
        // Just to ensure registration.waiting is available before calling postMessage()
        return;
      }
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  },
});
