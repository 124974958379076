const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors ={
  orangeColor:  '#f7901d',
  darkOrangeColor:'#c67214',
  darkGreyColor : '#283537',
  lightGreyColor : '#ebebeb',
};

const variables = {
  familyBase: '"montserrat",sans-serif',
  familyHeader: '"montserrat",serif',

  primaryColor: '#c20f2f',
  fontColor: '#404040',
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;