export const listingType = {
  Sale: 0,
  Rent: 1,
  Both: 2,
};

export function getListingTypeEnum(str) {
  if (str === "sale") return listingType.Sale;
  if (str === "rent") return listingType.Rent;
  if (str === "both") return listingType.Both;
  return null;
}
