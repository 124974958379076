import { fb, validators } from "../../../lib/form";
let enquiryTypeList = ["General", "Property Management", "Sell My Property", "Sales Enquiry", "Rental Enquiry", "Other"].map((x) => {
  return { name: x, value: x };
});
enquiryTypeList = [{ name: "How can we help you?", value: "" }, ...enquiryTypeList];

export function getContactModel(data) {
  const model = fb.group({
    name: [data.name || "", [validators.Required()], { label: "Name", type: "text" }],
    phone: [data.phone || "", [validators.Required()], { label: "Phone", type: "text" }],
    email: [data.email || "", [validators.Required(), validators.Email()], { label: "Email", type: "email" }],
    enquiry: [data.enquiry || "", [validators.Required()], { label: "Your Message", type: "textarea" }],
    attachments: [data.attachments || "", [], { label: "Attachments", type: "file" }],
    enquiryType: [data.enquiryType || "", [validators.Required()], { label: null, type: "select", options: enquiryTypeList }],
  });
  return model;
}
